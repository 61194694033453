import { template as template_54f664e7d9fe42dda5945071e71f3842 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat, fn } from '@ember/helper';
import { eq } from 'tio-ui/utilities';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import pageTitle from 'ember-page-title/helpers/page-title';
import Paginator from 'tio-common/components/tio/paginator';
import RouteTemplate from 'ember-route-template';
import SelectGeneric from 'tio-common/components/select/generic';
import Table from 'tio-common/components/table/index';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import TioSearchInput from 'tio-common/components/tio/search-input';
import TioTablist from 'tio-common/components/tio/tablist';
import TuitionAssistanceProgramInstancesTableRow from 'tio-common/components/tuition-assistance/program-instances/table/row';
import type EnumModel from 'tio-common/models/enum';
import type Owner from '@ember/owner';
import type PartnerService from 'tio-employee/services/partner';
import type StoreService from 'tio-common/services/store';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplateModel from 'tio-common/models/tas-program-template';
import type TuitionAssistanceProgramInstancesIndexController from 'tio-employee/controllers/authenticated/admin/tuition-assistance/program-instances/index';
const string = String;
interface S {
    Args: {
        controller: TuitionAssistanceProgramInstancesIndexController;
        model: TasProgramInstanceModel[];
    };
}
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
class TuitionAssistanceProgramInstancesIndexRouteComponent extends Component<S> {
    @service
    store: StoreService;
    @service
    partner: PartnerService;
    @tracked
    selectedStateEnum?: EnumModel;
    @tracked
    selectedProgramFilter?: TasProgramTemplateModel;
    constructor(owner: Owner, args: S['Args']){
        super(owner, args);
        this.setupMenus();
    }
    @action
    async setupMenus() {
        if (this.args.controller.state && !this.selectedStateEnum) {
            const selected = await this.store.query('enum', {
                filter: {
                    dot: this.args.controller.state
                }
            });
            this.selectedStateEnum = selected[0];
        }
        // Same logic as above
        if (this.args.controller.program && !this.selectedProgramFilter) {
            const selected = this.store.peekRecord('tasProgramTemplate', this.args.controller.program);
            this.selectedProgramFilter = selected;
        }
    }
    stateOptionsData = trackedFunction(this, async ()=>{
        const states = await this.store.query('enum', {
            filter: {
                flavor: 'TAS.ProgramInstanceState'
            }
        });
        return states;
    });
    get stateOptions() {
        return this.stateOptionsData.value || [];
    }
    programOptionsData = trackedFunction(this, async ()=>{
        if (!this.partner?.company) {
            return [];
        }
        const programs = await this.store.query('tas-program-template', {
            filter: {
                company: this.partner.company?.id
            }
        });
        return programs;
    });
    get programOptions() {
        return this.programOptionsData.value || [];
    }
    didSearch = restartableTask(async (string)=>{
        await timeout(INPUT_DEBOUNCE);
        this.args.controller.search = string;
    });
    @action
    setSelectedState(stateEnum: EnumModel) {
        this.args.controller.state = stateEnum?.dot || '';
        this.selectedStateEnum = stateEnum;
    }
    @action
    setSelectedProgramFilter(model: TasProgramTemplateModel) {
        this.selectedProgramFilter = model;
        this.args.controller.program = model?.id || '';
    }
    @action
    changePaginatorPage(page: number) {
        this.args.controller.page = page;
    }
    static{
        template_54f664e7d9fe42dda5945071e71f3842(`
    {{pageTitle (t "tuition_assistance.program_instances.program_applications")}}
    <VStack>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "tuition_assistance.applications.course_applications"}}
          @route="authenticated.admin.tuition-assistance.applications.index"
        />
        <tabs.tab
          @label={{t "tuition_assistance.program_instances.program_applications"}}
          @route="authenticated.admin.tuition-assistance.program-instances.index"
        />
      </TioPageTabs>
      <Section class="md:sticky md:top-0 z-10 bg-gray-50">
        <:body>
          <HStack>
            <div class="md:basis-80">
              {{! This label is aria-hidden because the search input has an explicit aria-label on it already }}
              <label class="text-xs" aria-hidden={{true}}>
                {{t "search"}}
              </label>

              <TioSearchInput
                @placeholder="App ID, Employee ID, Email"
                @searchString={{@controller.search}}
                @onInput={{this.didSearch.perform}}
                @onSubmit={{this.didSearch.perform}}
              />
            </div>

            <SelectGeneric
              @label={{t "tuition_assistance.applications.state_filter_label"}}
              @selected={{this.selectedStateEnum}}
              @options={{this.stateOptions}}
              @onChange={{this.setSelectedState}}
              {{! @glint-expect-error }}
              @allowClear={{true}}
              class="md:basis-80"
            >
              <:option as |state|>
                {{t (concat "status_message.to." state.dot)}}
              </:option>
            </SelectGeneric>

            <SelectGeneric
              @label="Filter by program"
              @selected={{this.selectedProgramFilter}}
              @options={{this.programOptions}}
              @onChange={{this.setSelectedProgramFilter}}
              {{! @glint-expect-error }}
              @allowClear={{true}}
              class="md:basis-80"
            >
              <:option as |program|>
                {{string (getSingleValueForTasField "PROGRAM_NAME" program.fields)}}
              </:option>
            </SelectGeneric>
          </HStack>

          <TioTablist class="mb-6 border-b" as |tablist|>
            <tablist.tab
              @title="Approvals Waiting On Me"
              @isSelected={{eq @controller.tab "urgent"}}
              @onClick={{fn (mut @controller.tab) "urgent"}}
              class="lg:min-w-72"
            />
            <tablist.tab
              @title="Approvals Waiting On Others"
              @isSelected={{eq @controller.tab "waiting"}}
              @onClick={{fn (mut @controller.tab) "waiting"}}
              class="lg:min-w-72"
            />
            <tablist.tab
              @title="All Other Applications"
              @isSelected={{eq @controller.tab "all"}}
              @onClick={{fn (mut @controller.tab) "all"}}
              class="lg:min-w-72"
            />
          </TioTablist>
        </:body>
      </Section>
      <Section class="overflow-x-auto">
        <:body>
          <Table @isLoading={{false}}>
            <:thead as |options|>
              <options.tr>
                <options.th>{{t "tuition_assistance.preapproval_app.application_id"}}</options.th>
                <options.th>{{t "tuition_assistance.preapproval_app.payroll_id"}}</options.th>
                <options.th>{{t "tuition_assistance.applications.partner_name"}}</options.th>
                <options.th>{{t "tuition_assistance.applications.employee_name"}}</options.th>
                <options.th>
                  {{t "tuition_assistance.applications.school_institution"}}
                </options.th>
                <options.th>
                  {{t "tuition_assistance.program_details.application_history.state"}}
                </options.th>
                <options.th>{{t "tuition_assistance.applications.last_action_by"}}</options.th>
                <options.th />
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model as |item|}}
                <TuitionAssistanceProgramInstancesTableRow
                  @model={{item}}
                  @routePrefix="authenticated.admin"
                  @rowClass={{options.rowClass}}
                  @cellClass={{options.cellClass}}
                />
              {{/each}}
            </:tbody>
            <:tfoot as |options|>
              <options.tr>
                <td colspan="7">
                  <Paginator
                    {{! @glint-expect-error: ed needs types}}
                    @totalItems={{@model.meta.page.total}}
                    @pageSize={{@controller.limit}}
                    @currentPage={{@controller.page}}
                    @onChange={{this.changePaginatorPage}}
                  />
                </td>
              </options.tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TuitionAssistanceProgramInstancesIndexRouteComponent);
