import { template as template_3415766e2f3347058fc16e9a739117c6 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type { AuthenticatedSyfRouteModel } from 'tio-employee/routes/authenticated/syf';
import PostEnrollment from 'tio-employee/components/syf/post-enrollment';
import type EmailAddressModel from 'tio-common/models/email-address';
import type Owner from '@ember/owner';
interface SyfPostEnrollmentRouteComponentSignature {
    Blocks: {
        default: [];
    };
    Args: {
        model: AuthenticatedSyfRouteModel;
    };
}
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
class SyfPostEnrollmentRouteComponent extends Component<SyfPostEnrollmentRouteComponentSignature> {
    @service
    sessionContext: SessionContextService;
    constructor(owner: Owner, args: SyfPostEnrollmentRouteComponentSignature['Args']){
        super(owner, args);
        // check for an existing personal email address on the match participant
        if (this.args.model.emailAddress?.isPersonal) {
            // if found, register existing personal email address as tracked var
            this.emailAddress = this.args.model.emailAddress;
        } else if (this.sessionContext.user.personalEmail) {
            this.emailAddress = this.sessionContext.user.personalEmail;
        } else {
            // if not, create a draft email address in the store and track
            this.emailAddress = this.store.createRecord('email-address', {
                contactType: 'PERSONAL',
                user: this.sessionContext.user,
                email: '',
                isActive: true
            });
        }
    }
    @service
    store: StoreService;
    @service
    router: RouterService;
    @tracked
    loading: boolean = false;
    @tracked
    emailAddress: EmailAddressModel;
    @action
    async onSubmitPostEnrollmentData() {
        try {
            this.loading = true;
            if (this.emailAddress.email) {
                // associate email address and match participant model
                this.args.model.emailAddress = this.emailAddress;
                await this.emailAddress.save();
            }
            // does this need to be 2 round trips?
            await this.args.model.save();
            this.router.transitionTo('authenticated.syf.dashboard');
        } catch (e) {
            // TODO: handle this if it fails, something better than logging it
            console.log(e);
        } finally{
            this.loading = false;
        }
    }
    @action
    onSkipPostEnrollmentData() {
        this.router.transitionTo('authenticated.syf.dashboard');
    }
    static{
        template_3415766e2f3347058fc16e9a739117c6(`
    <PostEnrollment
      @matchParticipant={{@model}}
      @emailAddress={{this.emailAddress}}
      @loading={{this.loading}}
      @onSubmit={{this.onSubmitPostEnrollmentData}}
      @onSkip={{this.onSkipPostEnrollmentData}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfPostEnrollmentRouteComponent);
