import { template as template_2641dee44fa34011bbf43120566390a0 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsCertificateCertificateDetails from 'tio-employee/components/tuition-assistance/forms/certificate/certificate-details';
import type { AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel } from 'tio-employee/routes/authenticated/tuition-assistance/programs/instance/certificate/edit';
interface TAProgramInstanceCertificateEditRouteComponentSignature {
    Args: {
        model: AuthenticatedTuitionAssistanceProgramsInstanceCertificateEditRouteModel;
    };
}
export default RouteTemplate<TAProgramInstanceCertificateEditRouteComponentSignature>(template_2641dee44fa34011bbf43120566390a0(`
    {{! @glint-expect-error something is wierd with the types on route}}
    <TuitionAssistanceFormsCertificateCertificateDetails @model={{@model}} />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
