import { template as template_98ff95c5006b4994ba980d3711cfd312 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsLoanForgivenessComponent: TOC = template_98ff95c5006b4994ba980d3711cfd312(`
  <LinkTo @route="authenticated.repaying-student-debt.loan-forgiveness">
    <Tile @headerText={{t "dashboard_tiles.loan_forgiveness"}}>
      <:description>
        <span>{{t "dashboard_tiles.learn_about_fed_forgiveness"}}</span>
        <p class="font-semibold my-4 text-xl">{{t "dashboard_tiles.what_is_lf"}}</p>
        <span>{{t "dashboard_tiles.lf_criteria"}}</span>
      </:description>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsLoanForgivenessComponent;
