import { template as template_4397eb2c9d644245a9971f51e4725406 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type PartnerDashboardsService from 'tio-employee/services/partner-dashboards';
import { t } from 'ember-intl';
export interface AppSidebarNavGroupsAdminReportsSignature {
    Args: {
        // TODO: type this like the tuition-assistance module
        // - James 2024-04-09
        nav: unknown;
    };
}
export default class AppSidebarNavGroupsAdminReportsComponent extends Component<AppSidebarNavGroupsAdminReportsSignature> {
    @service
    partnerDashboards: PartnerDashboardsService;
    get shouldDisplayIndexLink() {
        return this.partnerDashboards.dashboards.length > 1;
    }
    static{
        template_4397eb2c9d644245a9971f51e4725406(`
    {{#let @nav as |nav|}}
      {{! @glint-expect-error: no idea}}
      <nav.item @nestedRoute="admin.reports" @icon="book" @label={{t "common.reporting.default"}} />
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
