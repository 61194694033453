import { template as template_5c8109ea4774433fad6b4eb58cb0f277 } from "@ember/template-compiler";
import MaterialIcon from 'tio-common/components/material-icon';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
/**
 * Signature for the SlrErrorHeader component.
 *
 * @block default - The default block for yielding content inside the header.
 */ interface SlrErrorHeaderSignature {
    Blocks: {
        default: [];
    };
    Element: HTMLElement;
}
/**
 * SlrErrorHeader Component
 *
 * This component is the header section of an error message, displaying an icon and title.
 */ const SlrErrorHeader: TOC<SlrErrorHeaderSignature> = template_5c8109ea4774433fad6b4eb58cb0f277(`
  <div class={{SlrErrorOptions.headerClass}} ...attributes>
    <MaterialIcon @icon="warning_amber" class="text-red-500" />
    <h2 class="font-semibold">{{yield}}</h2>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
/**
 * Signature for the SlrErrorBody component.
 *
 * @block default - The default block for yielding content inside the body.
 */ interface SlrErrorBodySignature {
    Blocks: {
        default: [];
    };
    Element: HTMLElement;
}
/**
 * SlrErrorBody Component
 *
 * This component is the body section of an error message, displaying the main content of the error.
 */ const SlrErrorBody: TOC<SlrErrorBodySignature> = template_5c8109ea4774433fad6b4eb58cb0f277(`
  <p class={{SlrErrorOptions.bodyClass}} ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
/**
 * Signature for the SlrErrorFooter component.
 * @block default - The default block for yielding content inside the footer.
 */ interface SlrErrorFooterSignature {
    Blocks: {
        default: [];
    };
    Element: HTMLElement;
}
/**
 * SlrErrorFooter Component
 *
 * This component is the footer section of an error message, displaying additional information or actions.
 */ const SlrErrorFooter: TOC<SlrErrorFooterSignature> = template_5c8109ea4774433fad6b4eb58cb0f277(`
  <div class="flex flex-row justify-center" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
/**
 * Configuration options for the SlrError component.
 */ const SlrErrorOptions = {
    wrappingClass: 'outline outline-gray-100 rounded-md shadow-md p-4 bg-gray-50',
    headerClass: 'flex flex-row space-x-2 items-center',
    header: SlrErrorHeader,
    bodyClass: 'py-4 px-8',
    body: SlrErrorBody,
    footer: SlrErrorFooter
};
/**
 * Signature for the SlrError component.
 *
 * @block default - Yields the SlrErrorOptions for configuring the error message.
 */ interface SlrErrorSignature {
    Blocks: {
        default: [typeof SlrErrorOptions];
    };
    Element: HTMLElement;
}
/**
 * SlrError Component
 *
 * @example
 * ```gts
 * <SlrError>
 *   <:default as |errorOptions|>
 *     <errorOptions.header>
 *       Error Title
 *     </errorOptions.header>
 *     <errorOptions.body>
 *       Error message details.
 *     </errorOptions.body>
 *    <errorOptions.footer>
 *     <button>Close</button>
 *   </errorOptions.footer>
 *   </:default>
 * </SlrError>
 * ```
 */ const SlrError: TOC<SlrErrorSignature> = template_5c8109ea4774433fad6b4eb58cb0f277(`
  <div class={{SlrErrorOptions.wrappingClass}} ...attributes>
    {{yield SlrErrorOptions}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SlrError;
