import { template as template_1dc08349741e4fc0b88d352ec57fe7b1 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { Header, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_1dc08349741e4fc0b88d352ec57fe7b1(`
    <VStack>
      <Header>{{t "idr.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
