import { template as template_874dc05dcfd54aaf820e4edade4bde4c } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
interface SyfPaymentsRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfPaymentsRouteSignature>(template_874dc05dcfd54aaf820e4edade4bde4c(`{{outlet}}`, {
    eval () {
        return eval(arguments[0]);
    }
}));
