import { template as template_0c6f07fa92bc44949bbf1672466b609e } from "@ember/template-compiler";
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const FiveTwoNineSavingsComponent: TOC = template_0c6f07fa92bc44949bbf1672466b609e(`
  <div class="text-midnight text-2xl font-semibold">
    {{t "planning_for_college.five_two_nine.withdrawal_power_title"}}
  </div>
  <div class="w-full pt-4 text-base">
    {{t "planning_for_college.five_two_nine.withdrawal_power_text"}}
  </div>
  <div class="flex flex-col items-center justify-center my-10">
    <img
      class="w-full max-w-screen-md mb-6"
      alt={{t "planning_for_college.five_two_nine.tax_free_withdrawals"}}
      src="/assets/images/planning-for-college/tax-free-withdrawals-chart.png"
    />
    <div class="text-xs text-neutral-600 leading-5 m-auto">
      <div class="uppercase">
        {{t "planning_for_college.five_two_nine.graph_assumptions_title"}}
      </div>
      <div class="">
        {{t "planning_for_college.five_two_nine.graph_assumptions_text"}}
      </div>
    </div>
  </div>

  <div class="text-midnight text-2xl font-semibold">
    {{t "planning_for_college.five_two_nine.529_vs_tax"}}
  </div>
  <div class="w-full pt-4 text-base italic">
    {{t "planning_for_college.five_two_nine.over_18_years"}}
  </div>
  <div class="flex flex-col items-center justify-center my-10">
    <img
      class="w-full max-w-screen-sm mb-6"
      alt={{t "planning_for_college.five_two_nine.table_investments"}}
      src="/assets/images/planning-for-college/taxable-investments-chart.png"
    />
    <div class="text-xs text-neutral-600 leading-5 m-auto">
      <div class="uppercase">
        {{t "planning_for_college.five_two_nine.disclaimer_title"}}
      </div>
      <div class="">
        {{t "planning_for_college.five_two_nine.tax_disclaimer_text"}}
      </div>
      <div class="pt-6">
        {{t "planning_for_college.five_two_nine.tax_assumptions"}}
      </div>
    </div>
  </div>

  <div class="text-midnight text-2xl font-semibold">
    {{t "planning_for_college.five_two_nine.save_more_title"}}
  </div>
  <div class="w-full pt-4 text-base">
    {{t "planning_for_college.five_two_nine.little_savings_text"}}
  </div>
  <div class="flex flex-col items-center justify-center my-10">
    <img
      class="w-full max-w-screen-md mb-6"
      alt={{t "planning_for_college.five_two_nine.monthly_deposit_charts"}}
      src="/assets/images/planning-for-college/monthly-deposit-chart.png"
    />
    <div class="text-xs text-neutral-600 leading-5 m-auto">
      <div class="uppercase">
        {{t "planning_for_college.five_two_nine.disclaimer_title"}}
      </div>
      <div class="">
        {{t "planning_for_college.five_two_nine.save_more_disclaimer_text"}}
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FiveTwoNineSavingsComponent;
