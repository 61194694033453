import { template as template_a5d1181bb2884cd79138ce6bf39a80ba } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';
interface SyfRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfRouteSignature>(template_a5d1181bb2884cd79138ce6bf39a80ba(`
    <VStack>
      <Header>{{t "syf.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
