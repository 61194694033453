import { template as template_725e8d55344f47f68cb7286a31be6125 } from "@ember/template-compiler";
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import screenIs from 'tio-common/helpers/screen-is';
import type RecommendationModel from 'tio-common/models/recommendation';
import ForgivenessEstimatorForgivenessEstimatorForm from 'tio-employee/components/forgiveness-estimator/forgiveness-estimator-form';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
interface S {
    Args: {
        model: {
            // TODO: What is a loan summary? Where does it come from?
            loanSummary: LoanSummary;
        };
    };
}
class ForgivenessEstimatorIndexRoute extends Component<S> {
    @service
    router: RouterService;
    @action
    didSave(recommendation: RecommendationModel) {
        return this.router.transitionTo('authenticated.idr.dashboard.forgiveness-estimator.results', recommendation.id);
    }
    static{
        template_725e8d55344f47f68cb7286a31be6125(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.idr.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.idr.dashboard.forgiveness-estimator.index"
        @label="Forgiveness Estimator"
      />
    </TioPageBreadcrumbs>
    <div class="flex justify-center gap-8">
      <ForgivenessEstimatorForgivenessEstimatorForm
        @application="IDR"
        @loanSummary={{@model.loanSummary}}
        @didSave={{this.didSave}}
        class="md:max-w-3xl"
      />
      {{#if (screenIs "lg")}}
        <div class="p-6 mt-9">
          {{svgJar "infographic-screen" role="img" desc=(t "svg.infographic_screen")}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ForgivenessEstimatorIndexRoute);
