import { template as template_31da95a4978c49d48cf0fd35afd7e200 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type { NavGroup } from 'tio-common/components/ui/sidebar/nav';
import AppSidebarNavGroupsAdminTuitionAssistanceComponent from './nav-groups/admin/tuition-assistance';
export interface AppSidebarTasApproverNavSignature {
    Args: {
        nav: {
            group: NavGroup;
        };
    };
}
const AppSidebarTasApproverNavComponent: TOC<AppSidebarTasApproverNavSignature> = template_31da95a4978c49d48cf0fd35afd7e200(`
  {{#let @nav as |nav|}}
    <AppSidebarNavGroupsAdminTuitionAssistanceComponent @nav={{nav}} />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarTasApproverNavComponent;
