import { template as template_0ebaa1aee60b4ab382e8e5b98b839dcf } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { eq, or, not } from 'tio-ui/utilities';
import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { Select } from 'tio-ui/components/forms';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { type IntlService } from 'ember-intl';
import { v4 as uuid } from 'uuid';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import Component from '@glimmer/component';
import Document from 'ember-static-heroicons/components/outline-24/document';
import Drawer from 'tio-ui/components/drawer';
import FileUploader from 'tio-ui/components/file-uploader';
import RouteTemplate from 'ember-route-template';
import type { UploadFile, Queue } from 'ember-file-upload';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type ObservabilityService from 'tio-employee/services/observability';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type ObservablesService from 'tio-common/services/observables';
import type PersonModel from 'tio-common/models/person';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import UploadProgress from 'tio-employee/components/observability/upload-progress';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
import type { ObservableSource } from 'tio-employee/services/observability';
import { getObservableProductRoute, getRecommendedDocumentType, getInstitutionMatch } from 'tio-employee/services/observability';
import { Header, Section, VStack, HStack } from 'tio-ui/components/layout';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
import type Owner from '@ember/owner';
// wait 10s before telling users to wait for an email
const PROCESSING_TIMEOUT = 10000;
interface ObservabilityUploadRouteSignature {
    Args: {
        model: {
            source?: ObservableSource;
            person: PersonModel;
            financialInstitutions: FinancialInstitutionModel[];
        };
    };
}
class ObservabilityUploadRoute extends Component<ObservabilityUploadRouteSignature> {
    @service
    observability: ObservabilityService;
    @service
    observables: ObservablesService;
    @service
    fileQueue: Queue;
    @service
    intl: IntlService;
    @service
    remoteEventLogger: RemoteEventLoggerService;
    @tracked
    batchId?: string;
    @tracked
    instructionsOpen: boolean = false;
    @tracked
    instructionsType?: 'nslds' | 'statement' | 'transaction_history';
    // this can be an incomplete mock financial institution in order to hit the translation
    // select clause default case when the name doesn't match; see servicerOptions getter's
    // return value with mock servicer "General Instructions" spread into it
    @tracked
    financialInstitution?: FinancialInstitutionModel | {
        name: string;
    };
    @tracked
    upload?: UploadFile;
    @tracked
    observableDocument?: ObservableDocumentModel;
    subscription: ReturnType<typeof this.observability.cable.consumer>['subscriptions']['create'] | null = null;
    @tracked
    uploading = false;
    @tracked
    processing = false;
    @tracked
    uploadError?: Error;
    @tracked
    uploadTimeout: ReturnType<typeof setTimeout>;
    @tracked
    timedOut = false;
    constructor(owner: Owner, args: ObservabilityUploadRouteSignature['Args']){
        super(owner, args);
        this.initializeBatchId();
    }
    willDestroy() {
        clearTimeout(this.uploadTimeout);
        if (this.batchId) {
            this.observability.unsubscribeFromBatchStatus(this.batchId);
        }
    }
    get recommendedType(): 'nslds' | 'statement' | 'transaction_history' {
        return getRecommendedDocumentType(this.args.model.source);
    }
    // consider helperizing translation map outside component definition
    get recommendedDocumentTypeName(): string {
        switch(this.recommendedType){
            case 'statement':
                return this.intl.t('observability.statement');
            case 'transaction_history':
                return this.intl.t('observability.transaction_history');
            default:
                return this.intl.t('observability.default_document');
        }
    }
    get optionalType(): 'nslds' | 'statement' {
        return this.recommendedType === 'statement' ? 'nslds' : 'statement';
    }
    get servicerOptions() {
        const servicerName = this.intl.t('observability.uploads.placeholder_servicer');
        return [
            {
                name: servicerName
            },
            ...this.args.model.financialInstitutions
        ];
    }
    get drawerHeaderTranslation() {
        if (this.instructionsType === 'transaction_history') {
            return 'observability.uploads.transaction_history.prompt';
        } else if (this.instructionsType === 'nslds') {
            return 'observability.uploads.nslds.prompt';
        // base case equivalent: this.instructionsType === "statement"
        } else {
            return 'observability.uploads.statement.prompt';
        }
    }
    get instructionsTranslation() {
        if (this.instructionsType === 'transaction_history') {
            return 'observability.uploads.transaction_history.instructions';
        } else if (this.instructionsType === 'nslds') {
            return 'observability.uploads.nslds.instructions';
        // base case equivalent: this.instructionsType === "statement"
        } else {
            return 'observability.uploads.statement.instructions';
        }
    }
    get needsServicer() {
        return [
            'statement',
            'transaction_history'
        ].includes(<string>this.instructionsType);
    }
    @action
    openRecommendedInstructions(): void {
        this.instructionsType = this.recommendedType;
        this.instructionsOpen = true;
    }
    @action
    openOptionalInstructions(): void {
        this.instructionsType = this.optionalType;
        this.instructionsOpen = true;
    }
    @action
    openSyfPaymentsInstructions(): void {
        this.instructionsType = 'transaction_history';
        this.instructionsOpen = true;
    }
    @action
    closeInstructions(): void {
        this.instructionsOpen = false;
        this.instructionsType = undefined;
        this.financialInstitution = undefined;
    }
    @action
    setFinancialInstitution(name: string): void {
        this.financialInstitution = this.args.model.financialInstitutions.find((institution: FinancialInstitutionModel)=>institution.name === name) || {
            name: 'default'
        };
    }
    @action
    initializeBatchId() {
        this.batchId = uuid();
    }
    get queue() {
        // @ts-expect-error "Property 'findOrCreate' does not existon type 'Queue'" - yes it does
        return this.fileQueue.findOrCreate('observable-assets');
    }
    @action
    async handleFileAdded(upload: UploadFile): Promise<void> {
        this.upload = upload;
    }
    @action
    removeFile() {
        this.queue.remove(this.upload);
        this.upload = undefined;
    }
    @action
    async handleSubmit(e: SubmitEvent) {
        e.preventDefault();
        const { person, source } = this.args.model;
        this.uploading = true;
        this.subscription = this.observability.subscribeToBatchStatus(this.batchId!, source);
        const batch = this.batchId;
        this.remoteEventLogger.logObservabilityEvent({
            eventName: 'UPLOAD_START',
            component: 'ObservabilityUploadRoute'
        });
        this.observables.createObservableDocument(this.upload!, {
            person,
            batch
        }).then((document)=>{
            this.remoteEventLogger.logObservabilityEvent({
                eventName: 'UPLOAD_SUCCESS',
                component: 'ObservabilityUploadRoute',
                document
            });
            this.observableDocument = document;
            this.uploadTimeout = setTimeout(()=>(this.timedOut = true), PROCESSING_TIMEOUT);
            this.processing = true;
            this.uploading = false;
        }).catch((error)=>{
            this.remoteEventLogger.logObservabilityEvent({
                eventName: 'UPLOAD_FAILURE',
                component: 'ObservabilityUploadRoute'
            });
            this.uploadError = error;
        });
    }
    @action
    reset() {
        clearTimeout(this.uploadTimeout);
        this.uploading = false;
        this.processing = false;
        this.queue.remove(this.upload);
        this.upload = undefined;
        this.uploadError = undefined;
    }
    static{
        template_0ebaa1aee60b4ab382e8e5b98b839dcf(`
    <VStack>
      <Header>
        <LinkTo
          @route={{getObservableProductRoute @model.source}}
          class="block mb-4 align-bottom text-gray-900 font-medium"
        >
          <ChevronLeft class="w-5 inline" />
          {{t "back"}}
        </LinkTo>
        <p>{{t "observability.uploads.title" source=@model.source}}</p>
      </Header>
      <p>{{t "observability.uploads.subtitle" source=@model.source}}</p>
      <HStack>
        <Section>
          <:header>
            {{t "observability.uploads.form_section_title" source=@model.source}}
          </:header>
          <:body>
            <form {{on "submit" this.handleSubmit}}>
              {{#if this.upload}}
                {{! min height to match file uploader }}
                <Section class="min-h-[11rem]">
                  <:header>
                    {{t "observability.uploads.form.document.selected"}}
                  </:header>
                  <:body>
                    <div class="flex items-center px-4 pt-4 text-tio-gray-600">
                      <Document class="w-6 mr-2" />
                      <span>{{this.upload.file.name}}</span>
                      <button class="ml-auto" type="button" {{on "click" this.removeFile}}>
                        <XCircle class="w-6" />
                      </button>
                    </div>
                  </:body>
                </Section>
              {{else}}
                <h3 class="tio-h3">{{t "observability.uploads.form.document.label"}}</h3>
                <FileUploader
                  @name="observable-assets"
                  @onFileAdded={{this.handleFileAdded}}
                  @useDropzone={{true}}
                >
                  <button type="submit">
                    <Document class="w-4 mr-2" />
                    {{t "observability.uploads.form.document.input"}}
                  </button>
                </FileUploader>
              {{/if}}
              <Button
                @intent="primary"
                type="submit"
                disabled={{not this.upload}}
                class="mt-4 *:justify-center w-full"
              >
                {{t "observability.uploads.form.submit"}}
              </Button>
            </form>
          </:body>
        </Section>
        <Section>
          <:header>
            {{t "observability.uploads.instructions_section_title" source=@model.source}}
          </:header>
          <:body>
            {{#if (eq @model.source "syf_payments")}}
              <VStack @collapsed={{true}}>
                <Section>
                  <:header>{{t "observability.uploads.transaction_history.prompt"}}</:header>
                  <:body>
                    <ActionableTranslation
                      @t="observability.uploads.transaction_history.cta"
                      @params={{hash documentType=this.recommendedDocumentTypeName}}
                      @actions={{hash
                        openInstructions=(hash
                          t="click_here" onClick=this.openSyfPaymentsInstructions
                        )
                      }}
                    />
                  </:body>
                </Section>
              </VStack>
            {{else}}
              <VStack @collapsed={{true}}>
                <Section>
                  <:header>
                    <span class="block text-fuscia-300 text-sm mb-2">
                      {{t "observability.uploads.best_prefix"}}
                    </span>
                    {{#if (eq this.recommendedType "statement")}}
                      {{t "observability.uploads.statement.prompt"}}
                    {{else}}
                      {{t "observability.uploads.nslds.prompt"}}
                    {{/if}}
                  </:header>
                  <:body>
                    <ActionableTranslation
                      @t={{if
                        (eq this.recommendedType "statement")
                        "observability.uploads.statement.cta"
                        "observability.uploads.nslds.cta"
                      }}
                      @actions={{hash
                        openInstructions=(hash
                          t="click_here" onClick=this.openRecommendedInstructions
                        )
                      }}
                    />
                  </:body>
                </Section>
                <Section>
                  <:header>
                    <p class="text-gray-600 text-sm mb-2">
                      {{t "observability.uploads.optional_prefix"}}
                    </p>
                    {{#if (eq this.recommendedType "statement")}}
                      {{t "observability.uploads.nslds.prompt"}}
                    {{else}}
                      {{t "observability.uploads.statement.prompt"}}
                    {{/if}}
                  </:header>
                  <:body>
                    <ActionableTranslation
                      @t={{if
                        (eq this.recommendedType "statement")
                        "observability.uploads.nslds.cta"
                        "observability.uploads.statement.cta"
                      }}
                      @actions={{hash
                        openInstructions=(hash t="click_here" onClick=this.openOptionalInstructions)
                      }}
                    />
                  </:body>
                </Section>
              </VStack>
            {{/if}}
          </:body>
        </Section>
      </HStack>
    </VStack>
    <Modal @isOpen={{or this.uploading this.processing}} @allowClosing={{false}} @size="xl" as |m|>
      <m.Header>{{t "observability.uploads.progress.header"}}</m.Header>
      <m.Body>
        {{#if this.upload}}
          <UploadProgress
            @observableDocument={{this.observableDocument}}
            @source={{@model.source}}
            @upload={{this.upload}}
            @uploadError={{this.uploadError}}
            @onRetry={{this.reset}}
            @timedOut={{this.timedOut}}
          />
        {{/if}}
      </m.Body>
    </Modal>
    <Drawer @isOpen={{this.instructionsOpen}} @onClose={{this.closeInstructions}} @size="lg">
      <VStack>
        <Header>{{t this.drawerHeaderTranslation}}</Header>
        <VStack @collapsed={{true}}>
          {{#if this.needsServicer}}
            <span class="block font-semibold">
              {{t "observability.uploads.financial_institution.label"}}
            </span>
            <span class="block text-sm leading-none pt-4">
              {{t
                "observability.uploads.financial_institution.help"
                documentType=this.recommendedDocumentTypeName
              }}
            </span>
            <Select
              @items={{this.servicerOptions}}
              @allowEmpty={{true}}
              @onAction={{this.setFinancialInstitution}}
              @selectionMode="single"
              class="mt-4"
            >
              <:item as |i|>
                <i.Item @key={{i.item.name}}>{{i.item.name}}</i.Item>
              </:item>
            </Select>
            {{#if this.financialInstitution}}
              <article class="tio-prose">
                {{t
                  this.instructionsTranslation
                  servicer=(getInstitutionMatch this.financialInstitution.name)
                  htmlSafe=true
                }}
              </article>
            {{/if}}
          {{else}}
            <article class="tio-prose">
              {{t
                this.instructionsTranslation
                servicer=(getInstitutionMatch this.financialInstitution.name)
                htmlSafe=true
              }}
            </article>
          {{/if}}
        </VStack>
      </VStack>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ObservabilityUploadRoute);
