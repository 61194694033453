import { template as template_4d66beb8809646659ecbf97f41737ed6 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';
const QuestionsLoanForgivenessIncomeDrivenComponent: TOC = template_4d66beb8809646659ecbf97f41737ed6(`
  <VStack @collapsed={{true}}>
    {{! Income-Driven Loan Forgiveness }}
    <Header>{{t "pslf.questions.income_driven.idlf.title"}}</Header>
    <Section>
      <VStack>
        <p>{{t "pslf.questions.income_driven.idlf.description"}}</p>
        <ul class="list-disc">
          <li>
            {{t "pslf.questions.income_driven.idlf.item_1"}}
          </li>
          <li>
            {{t "pslf.questions.income_driven.idlf.item_2"}}
          </li>
        </ul>
      </VStack>
    </Section>
  </VStack>

  {{! Am I Eligible? }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.am_i_eligible"}}</Header>
    <Section>
      <VStack @collapsed={{true}}>
        <Section>
          <h1 class="font-semibold text-gray-600">
            {{t "pslf.questions.income_driven.borrower_eligibility.title"}}
          </h1>
          {{t "pslf.questions.income_driven.borrower_eligibility.description"}}
        </Section>
        <Section>
          <h1 class="font-semibold text-gray-600">
            {{t "pslf.questions.income_driven.loan_types.title"}}
          </h1>
          {{t "pslf.questions.income_driven.loan_types.description"}}
        </Section>
      </VStack>
    </Section>
  </VStack>

  {{! Plan Details }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.plan_details"}}</Header>
    <Section>
      <VStack @collapsed={{true}}>
        <Section>
          <h1 class="font-semibold text-gray-600">
            {{t "pslf.questions.income_driven.plan.forgiven_after.title"}}
          </h1>
          {{t "pslf.questions.income_driven.plan.forgiven_after.description"}}
        </Section>
        <Section>
          <h1 class="font-semibold text-gray-600">
            {{t "pslf.questions.income_driven.plan.max_forgiveness.title"}}
          </h1>
          {{t "pslf.questions.income_driven.plan.max_forgiveness.description"}}
        </Section>
        <Section>
          <h1 class="font-semibold text-gray-600">
            {{t "pslf.questions.income_driven.plan.tax_info.title"}}
          </h1>
          {{t "pslf.questions.income_driven.plan.tax_info.description"}}
        </Section>
      </VStack>
    </Section>
  </VStack>
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.income_driven.plan.pros.title"}}</Header>
    <Section>
      <ul class="pl-4 list-disc">
        <li>{{t "pslf.questions.income_driven.plan.pros.item_1"}}</li>
      </ul>
    </Section>
  </VStack>
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.income_driven.plan.cons.title"}}</Header>
    <Section>
      <ul class="pl-4 list-disc">
        <li>{{t "pslf.questions.income_driven.plan.cons.item_1"}}</li>
        <li>{{t "pslf.questions.income_driven.plan.cons.item_2"}}</li>
      </ul>
    </Section>
  </VStack>

  {{! Next Steps }}
  <VStack @collapsed={{true}}>
    <Header>{{t "pslf.questions.next_steps"}}</Header>
    <Section>
      <VStack>
        <h1 class="font-semibold text-gray-600">
          {{t "pslf.questions.teacher.make_the_switch.title"}}
        </h1>
        <ul class="pl-4 list-disc">
          <li>
            {{t "pslf.questions.income_driven.make_the_switch.item_1" htmlSafe=true}}
          </li>
        </ul>
        <h1 class="font-semibold text-gray-600">
          {{t "pslf.questions.need_more_help.title"}}
        </h1>
        <p>
          {{t "pslf.questions.need_more_help.description" htmlSafe=true}}
        </p>
      </VStack>
    </Section>
  </VStack>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default QuestionsLoanForgivenessIncomeDrivenComponent;
