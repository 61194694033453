import { template as template_0bf251f83fcd4c39b835f4acdd87a8e0 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { getSingleValueForTasField, getFieldLabelForFieldName } from 'tio-common/utils/tuition-assistance/fields';
import { Input } from 'tio-ui/components/forms';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { and } from 'tio-ui/utilities';
import CardGeneric from 'tio-ui/components/card-generic';
import Component from '@glimmer/component';
import TioAlert from 'tio-common/components/tio/alert';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'tio-common/services/store';
import type TasParticipantModel from 'tio-common/models/tas-participant';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
interface StartDateEligibilityComponentArgs {
    Args: {
        canApplyToProgram: boolean;
        applyToProgram: (date: string) => void;
        buttonText: string;
        instanceForExistingApp?: TASProgramInstanceModel;
        isMultipleInstanceProgram: boolean;
        programTemplate: TasProgramTemplate;
        tasParticipant: TasParticipantModel;
    };
}
export default class StartDateEligibilityComponent extends Component<StartDateEligibilityComponentArgs> {
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @tracked
    enteredDate: string;
    @tracked
    eligibilityError = false;
    @tracked
    errors = '';
    @tracked
    loading = false;
    inputClasses = {
        base: 'mr-4',
        input: 'max-h-10'
    };
    calculateEligibility(periodOfEligibility: [string, string][]) {
        if (periodOfEligibility.length === 0) {
            return false;
        }
        if (periodOfEligibility.length === 1) {
            const programEligibility = periodOfEligibility.flat();
            return (this.enteredDate >= programEligibility[0]! && this.enteredDate <= programEligibility[1]!);
        }
        for (const eligibility of periodOfEligibility){
            if (this.enteredDate >= eligibility[0] && this.enteredDate <= eligibility[1]) {
                return true;
            }
        }
    }
    get canApply() {
        return !(this.enteredDate !== undefined && this.args.canApplyToProgram && !this.eligibilityError);
    }
    get startEndSubmission() {
        const lock = getSingleValueForTasField('LOCK_EMPLOYEE_STATUS_BASED_ON', this.args.programTemplate.fields) as string;
        const startDateLabel = getFieldLabelForFieldName('COURSES_BEGIN_DATE', this.args.programTemplate.fields) as string;
        const endDateLabel = getFieldLabelForFieldName('COURSES_END_DATE', this.args.programTemplate.fields) as string;
        const lockType: Record<string, string> = {
            COURSE_START_DATE: startDateLabel.toLowerCase(),
            COURSE_END_DATE: endDateLabel.toLowerCase()
        };
        return lockType[lock];
    }
    get isTypeOneOrFour() {
        const type = this.args.programTemplate.typeClassification ?? '';
        return type === 'TAS.ProgramType.1' || type.startsWith('TAS.ProgramType.4');
    }
    @action
    enterDate(value: string) {
        this.eligibilityError = false;
        this.errors = '';
        this.enteredDate = value;
    }
    @action
    async checkEligibilityAndSubmit() {
        this.loading = true;
        const { id } = this.args.tasParticipant;
        const { code } = this.args.programTemplate;
        const dynamicEligibility = await this.store.adapterFor('tas-participant').dynamicEligibility(id, code);
        const programEligibility = await this.store.adapterFor('tas-participant').periodOfEligibility(id, code);
        if (dynamicEligibility && this.calculateEligibility(programEligibility)) {
            this.args.applyToProgram(this.enteredDate);
            this.loading = false;
        } else {
            this.loading = false;
            this.eligibilityError = true;
            this.errors = this.intl.t('start_date_eligibility.submitError');
        }
    }
    static{
        template_0bf251f83fcd4c39b835f4acdd87a8e0(`
    <CardGeneric class="p-4">
      <:header>
        <div class="flex items center" data-test-eligibility-card>
          <div class="flex-grow font-semibold">
            {{t "eligibility_check"}}
          </div>
        </div>
      </:header>
      <:body>
        <div>
          <p>
            {{t "start_date_eligibility.instruction" startEndSubmission=this.startEndSubmission}}
          </p>
        </div>
        <div class="flex my-8">
          <Input
            id="eligibility"
            @type="date"
            @classes={{this.inputClasses}}
            @value={{this.enteredDate}}
            @onChange={{this.enterDate}}
            @errors={{this.errors}}
          />
          <Button
            @intent="primary"
            {{on "click" this.checkEligibilityAndSubmit}}
            disabled={{this.canApply}}
            @isRunning={{this.loading}}
            class="uppercase w-60"
            data-test-submit-eligibility
          >
            {{@buttonText}}
          </Button>
        </div>
        {{#if (and @instanceForExistingApp this.isTypeOneOrFour)}}
          <div class="flex text-sm py-2">
            <span>
              {{t "tuition_assistance.you_already_have_an_active_program"}}
            </span>
            <span class="text-center mx-1">
              <TioClickableText
                @linkTo={{if
                  @isMultipleInstanceProgram
                  "authenticated.tuition-assistance.dashboard"
                  "authenticated.tuition-assistance.programs.instance.index"
                }}
                @linkToModel={{unless @isMultipleInstanceProgram @instanceForExistingApp}}
                @textClass="text-sm font-medium text-ocean-600 hover:text-ocean-800 underline"
              >
                {{t "click_here"}}
              </TioClickableText>
            </span>
            <span>
              {{t "tuition_assistance.to_view_program_details"}}
            </span>
          </div>
        {{/if}}
        {{#if this.eligibilityError}}
          <TioAlert @type="error" @allowDismiss={{false}} class="mb-4">
            <:header>
              <p class="font-bold" data-test-eligibility-error>
                {{t
                  "start_date_eligibility.errorHeader"
                  startEndSubmission=this.startEndSubmission
                }}
              </p>
            </:header>
            <:body>
              <p class="text-sm">
                {{t "start_date_eligibility.errorText"}}
              </p>
            </:body>
          </TioAlert>
        {{/if}}
      </:body>
    </CardGeneric>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
