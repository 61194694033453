import { template as template_25b898dad90645cfaa4f301adb0389d6 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import PslfForgivenessSearchTool from 'tio-employee/components/pslf/forgiveness-search/tool';
import { Divider } from 'tio-ui/components/utilities';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const QuestionsLoanForgivenessStateAndLocalComponent: TOC = template_25b898dad90645cfaa4f301adb0389d6(`
  {{! State and Local Forgiveness Programs }}
  <div class="mt-6">
    <h1 class="text-midnight text-xl font-semibold">{{t
        "pslf.questions.state_and_local.title"
      }}</h1>
    <p class="pt-4">{{t "pslf.questions.state_and_local.description"}}</p>
    <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
      <div class="pr-3">
        <div>
          <h1 class="font-semibold text-gray-600">{{t
              "pslf.questions.state_and_local.shorter_waiting.title"
            }}</h1>
          <p class="pt-4">{{t "pslf.questions.state_and_local.shorter_waiting.description"}}</p>
        </div>
        <div class="pt-6">
          <h1 class="font-semibold text-gray-600">{{t
              "pslf.questions.state_and_local.strict_requirements.title"
            }}</h1>
          <p class="pt-4">{{t "pslf.questions.state_and_local.strict_requirements.description"}}</p>
        </div>
      </div>
      <div class="pt-4 sm:pt-0 sm:pl-3">
        <h1 class="font-semibold text-gray-600">{{t
            "pslf.questions.state_and_local.more_loans.title"
          }}</h1>
        <p class="pt-4">{{t "pslf.questions.state_and_local.more_loans.description"}}</p>
      </div>
    </div>
  </div>

  <Divider class="my-16" />

  <h1 class="text-midnight text-xl font-semibold">
    {{t "pslf.questions.state_and_local.search_tool.title"}}
  </h1>
  <p>
    {{t "pslf.questions.state_and_local.search_tool.description"}}
  </p>

  {{! Search Tool }}
  <div>
    <PslfForgivenessSearchTool />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default QuestionsLoanForgivenessStateAndLocalComponent;
