import { template as template_e9d1395e10a34c2e831d2862c0ff881c } from "@ember/template-compiler";
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import ForgivenessEstimatorResultInstructions from 'tio-employee/components/forgiveness-estimator/result-instructions';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type RecomendationModel from 'tio-common/models/recommendation';
interface S {
    Args: {
        model: RecomendationModel;
    };
}
export default RouteTemplate<S>(template_e9d1395e10a34c2e831d2862c0ff881c(`
    {{pageTitle (t "recommendations_estimator.next_steps")}}
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.idr.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.idr.dashboard.forgiveness-estimator.index"
        @label="Forgiveness Estimator"
      />
      <b.crumb
        @route="authenticated.idr.dashboard.forgiveness-estimator.results.index"
        @model={{@model}}
        @label="Results"
      />
      <b.crumb
        @route="authenticated.idr.dashboard.forgiveness-estimator.results.next-steps"
        @label="Next Steps"
      />
    </TioPageBreadcrumbs>
    <ForgivenessEstimatorResultInstructions @recommendation={{@model}} />
    <div class="flex justify-center">
      <Button
        {{on "click" (transitionTo "authenticated.idr.dashboard")}}
        @intent="primary"
        @appearance="outlined"
      >
        {{t "recommendations_estimator.go_to_dashboard"}}
      </Button>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
