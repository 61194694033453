import { template as template_dbd558a018374481851d083894ad2d56 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { trackedFunction } from 'reactiveweb/function';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type EmployeeModel from 'tio-common/models/employee';
import { hash } from '@ember/helper';
import Table from './accounting-payments/table';
import Summary from './accounting-payments/summary';
export interface ContributionsAccountingPaymentsSignature {
    Args: {
        employee: EmployeeModel;
    };
    Blocks: {
        default: [{
                isLoaded: boolean;
                paymentData: unknown[];
                summaryData: unknown[];
                table: unknown;
                summary: unknown;
            }];
    };
}
export default class ContributionsAccountingPaymentsComponent extends Component<ContributionsAccountingPaymentsSignature> {
    @service
    store: typeof Store;
    @tracked
    isLoading = false;
    @tracked
    error = null;
    responseData = trackedFunction(this, async ()=>{
        const employee = await this.args.employee;
        try {
            this.isLoading = true;
            const data = await this.store.adapterFor('employee').getAccountingPaymentContributions(employee.id);
            return data;
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally{
            this.isLoading = false;
        }
    });
    get data() {
        return this.responseData.value || {};
    }
    get paymentsData() {
        return this.data.payments || [];
    }
    get summaryData() {
        return this.data.summary || {};
    }
    static{
        template_dbd558a018374481851d083894ad2d56(`
    {{yield
      (hash
        paymentData=this.paymentsData
        summaryData=this.summaryData
        isLoaded=this.isLoading
        table=(component Table model=this.paymentsData isLoading=this.isLoading)
        summary=(component Summary model=this.summaryData isLoading=this.isLoading)
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
