import { template as template_dbc811183c2b4eb3b135d26d05fab50f } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import Actions from './article-card/actions';
import AppContentCard from 'tio-common/components/app/content-card';
import Column from './article-card/column';
import TioButton from 'tio-common/components/tio/button';
import type { ComponentLike } from '@glint/template';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type { WithBoundArgs } from '@glint/template';
interface RecommendationsArticleCardSig {
    Args: {
        subtitle: string;
        title: string;
    };
    Blocks: {
        default: [{
                column: WithBoundArgs<typeof Column, 'title'>;
                actions: ComponentLike;
                button: WithBoundArgs<typeof TioButton, 'href'>;
            }];
    };
    Element: HTMLElement;
}
const RecommendationsArticleCard: TOC<RecommendationsArticleCardSig> = template_dbc811183c2b4eb3b135d26d05fab50f(`
  <article class="mb-4 tio-copy" ...attributes>
    <AppContentCard class="p-2 md:p-6" @title="">
      <h1 class="text-midnight font-semibold text-lg mb-2">{{@title}}</h1>
      <h2 class="text-xl mb-4">{{@subtitle}}</h2>

      {{yield
        (hash
          column=(component Column title="")
          actions=(component Actions)
          button=(component TioButton)
        )
      }}
    </AppContentCard>
  </article>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RecommendationsArticleCard;
