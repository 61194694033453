import { template as template_1d8acde5625b4ef7979ad87e28c5d3e0 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_1d8acde5625b4ef7979ad87e28c5d3e0(`
    <VStack>{{outlet}}</VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
