import { template as template_31bcde457ff643559ddbdb625a11564f } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { restartableTask, timeout } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import ENV from 'tio-employee/config/environment';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import PslfAdminCompanyFormsTable from 'tio-employee/components/pslf/admin/company-forms-table';
import RouteTemplate from 'ember-route-template';
import TioSearchInput from 'tio-common/components/tio/search-input';
import type { IntlService } from 'ember-intl';
import type { PslfForm } from 'tio-common/models/search';
import type AdminPslfFormsController from 'tio-employee/controllers/authenticated/admin/pslf/forms';
import type RouterService from '@ember/routing/router-service';
import type SearchModel from 'tio-common/models/search';
import type SessionContextService from 'tio-employee/services/session-context';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import type Owner from '@ember/owner';
export interface AdminPslfFormsRouteSignature {
    Args: {
        controller: AdminPslfFormsController;
        model: SearchModel<PslfForm>;
    };
}
const INPUT_DEBOUNCE = ENV.environment === 'test' ? 0 : 250;
class AdminPslfFormsRoute extends Component<AdminPslfFormsRouteSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @tracked
    selectedGroupFilter: {
        text: string;
        value: string;
    } | null = null;
    @tracked
    selectedStatusFilter: {
        text: string;
        value: string;
    } | null = null;
    statusOptions = [
        'borrower_signed',
        'employer_signed',
        'rejected',
        'awaiting_submit',
        'submit_pending',
        'submit_errored',
        'submitted',
        'cancelled',
        're_submitted',
        're_submit_pending',
        're_submit_errored',
        'expired'
    ];
    statusFilterItems = this.statusOptions.map((status)=>{
        if (status === 'borrower_signed') {
            return {
                text: this.intl.t(`common.pslf.status.needs_review`),
                value: status
            };
        }
        return {
            text: this.intl.t(`common.pslf.status.${status}`),
            value: status
        };
    });
    groupFilterItems = this.groups.map((group)=>{
        return {
            text: group.employer.legalName,
            value: group.id
        };
    });
    constructor(owner: Owner, args: AdminPslfFormsRouteSignature['Args']){
        super(owner, args);
        this.selectedStatusFilter = this.statusFilterItems.find((statusFilterItem)=>statusFilterItem.value === args.controller.status) || null;
        this.selectedGroupFilter = this.groupFilterItems.find((groupFilterItem)=>groupFilterItem.value === args.controller.group) || null;
    }
    get groups() {
        return this.company?.companySetting.modules.pslf?.groups || [];
    }
    get company() {
        return this.sessionContext.currentRole?.company;
    }
    get companyName() {
        return this.company?.name || '';
    }
    @action
    setSelectedGroupFilter(groupItem: {
        text: string;
        value: string;
    }) {
        this.args.controller.group = groupItem?.value || '';
        this.selectedGroupFilter = groupItem;
    }
    @action
    setSelectedStatusFilter(statusItem: {
        text: string;
        value: string;
    }) {
        this.args.controller.status = statusItem?.value || '';
        this.selectedStatusFilter = statusItem;
    }
    didSearch = restartableTask(async (string)=>{
        await timeout(INPUT_DEBOUNCE);
        this.args.controller.search = string;
    });
    static{
        template_31bcde457ff643559ddbdb625a11564f(`
    <VStack>
      <Header>{{concat this.companyName " " (t "pslf.forms.index_page_title")}}</Header>
      <div class="grid grid-rows-1 grid-flow-col gap-4 mt-6 mb-8">
        <div>
          <label class="font-semibold text-gray-600" aria-hidden={{true}}>
            {{t "pslf.forms.search_forms"}}
          </label>
          <TioSearchInput
            @placeholder="Search (Name, Email)"
            @searchString={{@controller.search}}
            @onInput={{this.didSearch.perform}}
            @onSubmit={{this.didSearch.perform}}
            {{! @glint-expect-error: power select bug}}
            @allowClear={{true}}
          />
        </div>
        <div>
          <FormSelect
            data-legacy-input
            @label="Filter by PSLF group"
            @options={{this.groupFilterItems}}
            @selected={{this.selectedGroupFilter}}
            @onChange={{this.setSelectedGroupFilter}}
            {{! @glint-expect-error: power select bug}}
            @allowClear={{true}}
            as |item|
          >
            {{! @glint-expect-error: power select bug}}
            {{item.text}}
          </FormSelect>
        </div>
        <div>
          <FormSelect
            data-legacy-input
            @label="Filter by status"
            @options={{this.statusFilterItems}}
            @selected={{this.selectedStatusFilter}}
            @onChange={{this.setSelectedStatusFilter}}
            {{! @glint-expect-error: power select bug}}
            @allowClear={{true}}
            as |item|
          >
            {{! @glint-expect-error: issue with frontile types}}
            {{item.text}}
          </FormSelect>
        </div>
      </div>
      <Section>
        <:body>
          <PslfAdminCompanyFormsTable @model={{@model}} @controller={{@controller}} />
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AdminPslfFormsRoute);
