import { template as template_0d663655b95e49a38ee7749db10a03c1 } from "@ember/template-compiler";
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_0d663655b95e49a38ee7749db10a03c1(`
    <div>
      <QuestionsLoanForgiveness
        @indexRoute="authenticated.questions-you-have.loan-forgiveness.index"
        @incomeRoute="authenticated.questions-you-have.loan-forgiveness.income-driven"
        @teacherRoute="authenticated.questions-you-have.loan-forgiveness.teacher"
        @stateRoute="authenticated.questions-you-have.loan-forgiveness.state-and-local"
      >
        {{outlet}}
      </QuestionsLoanForgiveness>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
