import { template as template_6f65f61fce104bd684c6c8b4fae3b686 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsInviteFamilyComponent: TOC = template_6f65f61fce104bd684c6c8b4fae3b686(`
  <Tile @headerText={{t "dashboard_tiles.starbucks_scap"}}>
    <:description>
      {{t "dashboard_tiles.starbucks_scap_description"}}
      <p class="font-semibold my-4 text-xl">{{t "dashboard_tiles.starbucks_scap_title_1"}}</p>
      {{t "dashboard_tiles.starbucks_scap_blurb_1" htmlSafe=true}}
      <p class="font-semibold my-4 text-l">
        {{t "dashboard_tiles.starbucks_scap_title_2"}}
      </p>
      <LinkTo
        class="underline text-ocean-600"
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard"
      >
        {{t "dashboard_tiles.starbucks_scap_blurb_2"}}
      </LinkTo>
    </:description>
  </Tile>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsInviteFamilyComponent;
