import { template as template_c238b22595fb4ad3abc42038640a4d29 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
export default RouteTemplate(template_c238b22595fb4ad3abc42038640a4d29(`
    {{pageTitle (t "recommendations_estimator.forgiveness_estimator")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
