import { template as template_9d8b261c59734ff3a9b48b7729a1d0a0 } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { eq, or } from 'tio-ui/utilities';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import tippy from 'ember-tippy/modifiers/tippy';
import type { PslfForm } from 'tio-common/models/search';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type StoreService from '@ember-data/store';
interface PslfAdminCompanyFormsTableRowSig {
    Args: {
        model: PslfForm;
        rowClass: string;
        cellClass: string;
    };
}
export default class PslfAdminCompanyFormsTableRowComponent extends Component<PslfAdminCompanyFormsTableRowSig> {
    @service
    intl: IntlService;
    @service
    store: typeof StoreService;
    get isPriorEmployerForm(): boolean {
        return this.args.model?.formType === 'priorEmployer';
    }
    get isNonPartnerApproverForm(): boolean {
        return !!this.args.model?.formNonPartner;
    }
    get needsReview(): boolean {
        return (this.args.model?.formStatus === 'borrower_signed' && !this.isPriorEmployerForm && !this.isNonPartnerApproverForm);
    }
    get approverDisplayEmail(): string {
        const model = this.args.model;
        if (model.formPrintAndMail) {
            return `${this.intl.t('pslf.table.not_applicable')} - ${this.intl.t('pslf.table.print_and_mail')}`;
        }
        if (model.employerClosed) {
            return `${this.intl.t('pslf.table.not_applicable')} - ${this.intl.t('pslf.table.prior_employer_closed')}`;
        }
        if (this.isPriorEmployerForm || model.formNonPartner) {
            return model.borrowerEmployerEmail || '';
        }
        return model.employerEmail || '';
    }
    // TODO: Why don't we store this date as YYYY-MM-DD?
    get employerSignDateDumbFormat(): string {
        return this.args.model.employerSignDate.replace('-', '/') || '';
    }
    @action
    async needsUrl() {
        let form = await this.store.findRecord('pslf-form', this.args.model.formId, {
            include: 'attachments'
        });
        // @ts-expect-error: this will be addressed with ed update
        const attachment = form.attachments?.find((attachment)=>{
            return attachment.tags.includes(`document_id:${this.args.model.documentId}`);
        });
        return attachment?.signedUrl;
    }
    static{
        template_9d8b261c59734ff3a9b48b7729a1d0a0(`
    <tr class={{@rowClass}}>
      <td class={{@cellClass}}>{{@model.payrollId}}</td>
      <td class={{@cellClass}}>{{@model.borrowerName}}</td>
      {{#if (eq @model.formStatus "borrower_signed")}}
        <td class="{{@cellClass}} text-red-600 items-center font-semibold truncate underline">
          {{#if this.needsReview}}
            <LinkTo
              @route="authenticated.admin.pslf.form.review"
              @model={{@model.formId}}
              {{! target="_blank"
                rel="noopener noreferrer" }}
              {{tippy (t "pslf.status.click_here_to_start") placement="top" delay="500"}}
            >
              {{t "common.pslf.status.needs_review"}}
            </LinkTo>
          {{/if}}
        </td>
      {{else}}
        <td class={{@cellClass}}>{{t (concat "common.pslf.status." @model.formStatus)}}</td>
      {{/if}}
      <td class={{@cellClass}}>{{this.approverDisplayEmail}}</td>
      {{#let @model.borrowerSignDate as |date|}}
        <td class={{@cellClass}} title={{date}}>
          {{#if date}}
            {{safeFormatDate date month="short" day="2-digit" year="numeric"}}
          {{else}}
            {{t "pslf.table.pending"}}
          {{/if}}
        </td>
      {{/let}}
      {{#let @model.employerSignDate as |date|}}
        <td class={{@cellClass}} title={{date}}>
          {{#if date}}
            {{safeFormatDate
              this.employerSignDateDumbFormat
              month="short"
              day="2-digit"
              year="numeric"
              locale="en-us"
            }}
          {{else if (or @model.formPrintAndMail @model.employerClosed)}}
            {{t "pslf.table.not_applicable"}}
          {{else if @model.employerSignDate}}
            {{t "pslf.table.pending"}}
          {{/if}}
        </td>
      {{/let}}
      <td class={{@cellClass}}>
        <div class="truncate">
          {{t (concat "common.pslf.form-type." @model.formType)}}
        </div>
      </td>
      <td class={{@cellClass}}>
        {{#if (or @model.borrowerSignDate @model.employerSignDate)}}
          <TioDownloadButton
            @needsUrl={{this.needsUrl}}
            @filename={{concat @model.borrowerName " - " @model.formId}}
            class="border-none bg-transparent"
          />
        {{else}}
          {{! template-lint-disable no-bare-strings }}
          No Download
        {{/if}}
      </td>
    </tr>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
