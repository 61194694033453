import { template as template_84d1e74508bf4e5ba319b4fd59d88a8f } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import screenIs from 'tio-common/helpers/screen-is';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import type RecommendationModel from 'tio-common/models/recommendation';
import { action } from '@ember/object';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import StrategyFinderStrategyFinderForm from 'tio-common/components/strategy-finder/strategy-finder-form';
import { HStack, Section } from 'tio-ui/components/layout';
interface S {
    Args: {
        model: RecommendationModel;
    };
}
class StrategyFinderDashboardQuestionaireIndexRoute extends Component<S> {
    @service
    router: RouterService;
    @action
    didSave(recommendation: RecommendationModel) {
        this.router.transitionTo('authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results', recommendation);
    }
    static{
        template_84d1e74508bf4e5ba319b4fd59d88a8f(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.index"
        @label="Dashboard"
      />
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.index"
        @label="Strategy Finder"
      />
    </TioPageBreadcrumbs>
    <Section>
      <HStack>
        <StrategyFinderStrategyFinderForm
          @user={{@model.user}}
          @application="STRATEGY_FINDER"
          @loanSummary={{@model.loanSummary}}
          @didSave={{this.didSave}}
          class="md:max-w-3xl ml-4"
        />
        {{#if (screenIs "lg")}}
          {{svgJar "infographic-screen" role="img" desc=(t "svg.infographic_screen")}}
        {{/if}}
      </HStack>
    </Section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(StrategyFinderDashboardQuestionaireIndexRoute);
