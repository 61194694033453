import { template as template_7eb463fff31e481db1ac17a03fea0ef0 } from "@ember/template-compiler";
import RecommendationHistory from './results/recommendation-history';
import ResultsDashboardTable from '../forgiveness-estimator/results-dashboard-table';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type RecommendationModel from 'tio-common/models/recommendation';
import WhatHappensNext from './results/what-happens-next';
export interface RecDashResultsSig {
    Args: {
        allRecommendations: RecommendationModel[];
        accounts: AccountModel[];
        loanSummary: LoanSummary;
        person: PersonModel;
        programType: 'pslf' | 'sf' | 'idr';
        recommendation: RecommendationModel;
    };
    Element: HTMLElement;
}
const DashResults: TOC<RecDashResultsSig> = template_7eb463fff31e481db1ac17a03fea0ef0(`
  <ResultsDashboardTable
    @recommendation={{@recommendation}}
    @programType={{@programType}}
    ...attributes
  />
  <WhatHappensNext
    @accounts={{@accounts}}
    @person={{@person}}
    @programType={{@programType}}
    @loanSummary={{@loanSummary}}
  />
  <RecommendationHistory
    @programType={{@programType}}
    @allRecommendations={{@allRecommendations}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashResults;
