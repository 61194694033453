import { template as template_5761310abc4a44f189d665f262355cef } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface S {
    Args: {
        hasContributions: boolean;
        nav: unknown;
    };
}
const AppSidebarNavGroupsEmployeeMyAccountActivityComponent: TOC<S> = template_5761310abc4a44f189d665f262355cef(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.group
      @icon="account_circle"
      @label="My Account Activity"
      @nestedRoute="account-activity"
      as |group|
    >
      <group.item @nestedRoute="accounts" @label="Student Loan Summary" />
      {{#if @hasContributions}}
        <group.item @route="authenticated.slr.dashboard" @label="Employer Contributions" />
      {{/if}}
      <group.item @route="authenticated.observability.upload" @label="Add Student Loans" />
      {{! @glint-expect-error: no idea}}
    </nav.group>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsEmployeeMyAccountActivityComponent;
