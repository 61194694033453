import { template as template_3a8b40a1173d449a9b71434885fa11a1 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface AppPageProsVsConsSignature {
    Args: {
        cons?: string[];
        pros?: string[];
    };
}
const AppPageProsVsConsComponent: TOC<AppPageProsVsConsSignature> = template_3a8b40a1173d449a9b71434885fa11a1(`
  <div class="flex flex-col sm:grid sm:grid-cols-2">
    <div class="p-3">
      <h1 class="font-semibold text-lg text-gray-600">
        {{t "plan_details.pros"}}
      </h1>
      <ul class="p-4 pt-0 list-disc">
        {{#each @pros as |pro|}}
          <li>{{t pro htmlSafe=true}}</li>
        {{/each}}
      </ul>
    </div>
    <div class="p-3">
      <h1 class="font-semibold text-lg text-gray-600">
        {{t "plan_details.cons"}}
      </h1>
      <ul class="p-4 pt-0 list-disc">
        {{#each @cons as |con|}}
          <li>{{t con htmlSafe=true}}</li>
        {{/each}}
      </ul>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppPageProsVsConsComponent;
