import { template as template_9493407afe8d48bc8e09a70044cfa43e } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsPreApprovalApplicationReview from 'tio-employee/components/tuition-assistance/forms/pre-approval/application-review';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { t } from 'ember-intl';
import type TasGroupModel from 'tio-common/models/tas-group';
import { Section } from 'tio-ui/components/layout';
interface S {
    Args: {
        model: {
            programInstance: TasProgramInstanceModel;
            tasGroups: TasGroupModel[];
            activeInstances: TasProgramInstanceModel[];
        };
    };
}
export default RouteTemplate<S>(template_9493407afe8d48bc8e09a70044cfa43e(`
    <Section>
      <:header>{{t "tuition_assistance.preapproval_app.application_review"}}</:header>
      <:body>
        <TuitionAssistanceFormsPreApprovalApplicationReview
          @programInstance={{@model.programInstance}}
          @tasGroups={{@model.tasGroups}}
          @activeInstances={{@model.activeInstances}}
        />
      </:body>
    </Section>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
