import { template as template_662b54df1e7f43d68cad4fefeedfa464 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsGlossaryComponent: TOC = template_662b54df1e7f43d68cad4fefeedfa464(`
  <LinkTo @route="authenticated.questions-you-have.glossary">
    <Tile @headerText={{t "dashboard_tiles.glossary"}}>
      <:description>
        <p class="font-semibold pb-3 text-xl sm:text-lg xl:text-xl">
          {{t "dashboard_tiles.commonly_used_terms"}}
        </p>
        <span>{{t "dashboard_tiles.glossary_blurb"}}</span>
      </:description>
      <:image>
        {{svgJar
          "student-loan-glossary"
          width="80%"
          height="80%"
          role="img"
          desc=(t "svg.student_loan_glossary")
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsGlossaryComponent;
