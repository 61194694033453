import { template as template_9bbcbf88868349ca84aad40ed33f0552 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const AuthRegisterLoginHereComponent: TOC<{
    Args: {
        hideDescription?: boolean;
    };
}> = template_9bbcbf88868349ca84aad40ed33f0552(`
  <div data-test-login-here class="mb-6 text-md text-center">
    {{unless @hideDescription (t "login.already_have_account")}}
    <a class="underline text-ocean-600" href="/login">
      {{t "login.log_in_here"}}
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AuthRegisterLoginHereComponent;
