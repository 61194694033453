import { template as template_9be3d59a09234f3e8eef73f5e4971ed4 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
// import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountTable from 'tio-employee/components/accounts/account-table';
// import CombinedAverageTrend from 'tio-employee/components/charts/combined-average-trend';
import Component from '@glimmer/component';
// import HistoricalAccountTrend from 'tio-employee/components/charts/historical-account-trend';
// import LoanSummaryChart from 'tio-employee/components/charts/loan-summary-chart';
// import PrincipalAndInterestChart from 'tio-employee/components/charts/principal-and-interest-chart';
import RouteTemplate from 'ember-route-template';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type UserModel from 'tio-common/models/user';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import { and, not } from 'tio-ui/utilities';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
import Table from 'tio-common/components/table/index';
import Paginator from 'tio-common/components/tio/paginator';
import Badge from 'tio-ui/components/badge';
import type { Intent } from 'tio-ui/utilities';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { getProvider, getStatus } from 'tio-common/helpers/observability';
import type PersonModel from 'tio-common/models/person';
import { trackedFunction } from 'reactiveweb/function';
import { fn } from '@ember/helper';
import didIntersect from 'ember-scroll-modifiers/modifiers/did-intersect';
import type StoreService from 'tio-common/services/store';
import TioPdfModal from 'tio-common/components/tio/pdf-modal';
import embedPdf from 'tio-common/modifiers/embed-pdf';
import { LinkTo } from '@ember/routing';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
const getStatusIntent = (status: string): Intent | undefined =>{
    switch(status){
        case 'PROCESSING':
            return 'primary';
        case 'INVALID':
        case 'FAILED':
        case 'NOT_APPLIED':
            return 'error';
        case 'PROCESSED':
        case 'APPLIED':
            return 'success';
        case 'IN_DISPUTE':
        case 'PARTIALLY_APPLIED':
            return 'warning';
    }
};
const getSubmittedIcon = (via: string)=>{
    if (via === 'EMAIL') {
        return Envelope;
    }
    return DocumentArrowUp;
};
const getCreatedAt = (document: ObservableDocumentModel)=>{
    const createdAtDate = new Date(document.createdAt);
    return createdAtDate.toLocaleString();
};
interface RouteSignature {
    Args: {
        model: UserModel;
    };
}
class AccountActivityAccountRouteComponent extends Component<RouteSignature> {
    @service
    employee: EmployeeService;
    @tracked
    showAddLoansModal = false;
    get user() {
        return this.args.model;
    }
    get accounts() {
        return this.user?.activeAccounts || [];
    }
    get paidAccounts() {
        return this.accounts.filter((account)=>account.isPaidOff);
    }
    get unpaidAccounts() {
        return this.accounts.filter((account)=>!account.isPaidOff);
    }
    get loans() {
        return this.accounts.map((account)=>account.activeLoans).flat() || [];
    }
    get loansTotal() {
        const total = this.loans.reduce((sum, loan)=>{
            return (sum += loan.currentBalance);
        }, 0);
        return total.toFixed(2) as unknown as number;
    }
    get hasContributions() {
        return this.employee.hasContributions;
    }
    get accountWithContribution() {
        if (this.hasContributions) {
            return this.accounts.find((e)=>e.publicOrdinalValue == 0);
        } else {
            return null;
        }
    }
    get showContributionSelector() {
        return this.hasContributions && this.accounts.length > 1;
    }
    @action
    toggleAddLoansModal() {
        this.showAddLoansModal = !this.showAddLoansModal;
    }
    @action
    async changeAccountWithContribution(account: AccountModel) {
        this.accounts.forEach((a)=>(a.publicOrdinalValue = 1));
        account.publicOrdinalValue = 0;
        return this.accounts.map((a)=>a.save());
    }
    static{
        template_9be3d59a09234f3e8eef73f5e4971ed4(`
    {{pageTitle "Student Loan Summary"}}
    <VStack>
      <Header>
        <div class="flex justify-between items-center">
          <p>{{t "accounts.my_accounts"}}</p>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" (transitionTo "authenticated.observability.upload")}}
          >
            {{t "slr.add_accounts"}}
          </Button>
        </div>
      </Header>

      {{#if (and (not @model.activeAccounts) @model.person.needsToAnswerWhyNoLinkedLoans)}}
        <div class="col-span-6 mb-4">
          <AppAlertNoLinkedLoans @person={{@model.person}} />
        </div>
      {{/if}}
      {{#each this.unpaidAccounts as |account|}}
        <Section>
          <:body>
            <AccountTable @account={{account}} />
          </:body>
        </Section>
      {{/each}}
      {{!-- <Section>
        <VStack @collapsed={{true}}>
          <HStack @collapsed={{true}}>
            <Section>
              <:header>
                {{t "current_balance"}}
                {{safeFormatNumber this.loansTotal style="currency" currency="USD"}}
              </:header>
              <:body>
                <div class="h-[400px]">
                  <LoanSummaryChart @loans={{this.loans}} />
                </div>
              </:body>
            </Section>
            <Section>
              <:header>
                {{t "accounts.principal_and_interest" htmlSafe=true}}
                {{safeFormatNumber this.loansTotal style="currency" currency="USD"}}
              </:header>
              <:body>
                <div class="h-[400px]">
                  <PrincipalAndInterestChart @loans={{this.loans}} />
                </div>
              </:body>
            </Section>
          </HStack>
          <HStack @collapsed={{true}}>
            <Section class="w-full">
              <:header>{{t "accounts.historical_loan_trend"}}</:header>
              <:body>
                <div class="h-[400px]">
                  <CombinedAverageTrend @loans={{this.loans}} as |axisDates|>
                    <div class="whitespace-normal"> {{axisDates}} </div>
                  </CombinedAverageTrend>
                </div>
              </:body>
            </Section>
            <Section class="w-full">
              <:header>{{t "accounts.historical_account_trend"}}</:header>
              <:body>
                <div class="h-[400px]">
                  <HistoricalAccountTrend @accounts={{this.accounts}} as |axisDates|>
                    <div class="whitespace-normal"> {{axisDates}} </div>
                  </HistoricalAccountTrend>
                </div>
              </:body>
            </Section>
          </HStack>
        </VStack>
      </Section> --}}
      {{! <Section>
        <:body>
          <VStack class="sm:w-1/2 md:w-1/3"> }}
      {{! TODO: where does this get saved? }}
      {{!-- {{#if this.showContributionSelector}}
              <form>
                <FormSelect
                  data-legacy-input
                  {{! @glint-expect-error: frontile doesn't support this yet}}
                  @allowClear={{true}}
                  @label="Select an account to receive contributions"
                  @options={{this.accounts}}
                  @selected={{this.accountWithContribution}}
                  @onChange={{this.changeAccountWithContribution}}
                  as |account|
                >
                  {{! @glint-expect-error: frontile doesn't support this yet}}
                  {{account.institutionName}}
                </FormSelect>
              </form>
            {{/if}} --}}
      {{! </VStack>
        </:body>
      </Section> }}
      {{! TODO: only show section if there are documents to display }}
      <Section>
        <:header>{{t "accounts.my_documents"}}</:header>
        <:body>
          <DocumentsTable @person={{@model.person}} />
        </:body>
      </Section>
      {{#if this.paidAccounts.length}}
        <Section>
          <:header>{{t "accounts.paid_in_full"}}</:header>
          <:body>
            <Table>
              <:thead as |options|>
                <options.tr>
                  <options.th>{{t "accounts.servicer"}}</options.th>
                  <options.th>{{t "accounts.number_of_loans"}}</options.th>
                </options.tr>
              </:thead>
              <:tbody as |options|>
                {{#each this.paidAccounts as |account|}}
                  <options.tr>
                    <options.td>{{account.institutionName}}</options.td>
                    <options.td>{{account.activeLoans.length}}</options.td>
                  </options.tr>
                {{/each}}
              </:tbody>
            </Table>
          </:body>
        </Section>
      {{/if}}
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AccountActivityAccountRouteComponent);
const PAGE_SIZE = 10;
interface S {
    Args: {
        person: PersonModel;
    };
}
class DocumentsTable extends Component<S> {
    @service
    store: typeof StoreService;
    @tracked
    page = 1;
    @tracked
    limit = PAGE_SIZE;
    @tracked
    showPreview = false;
    @tracked
    isInViewport = false;
    @tracked
    pdfSrc = '';
    documentsData = trackedFunction(this, async ()=>{
        const person = this.args.person;
        const query = {
            filter: {
                person: person.id
            },
            include: 'observable-asset',
            sort: '-created-at',
            page: {
                limit: PAGE_SIZE,
                offset: (this.page - 1) * PAGE_SIZE
            }
        };
        return this.store.query('observable-document', query);
    });
    get documents() {
        return this.documentsData?.value || [];
    }
    @action
    changePaginatorPage(page: number) {
        this.page = page;
    }
    @action
    closePreviewModal() {
        this.showPreview = false;
    }
    @action
    async getDocument(document: ObservableDocumentModel) {
        // TODO: Can we query the asset directly?
        const doc = await this.store.findRecord('observable-document', document.id, {
            include: 'observable-asset'
        });
        this.pdfSrc = doc.observableAsset.signedUrl;
        this.showPreview = true;
    }
    static{
        template_9be3d59a09234f3e8eef73f5e4971ed4(`
    <Table {{didIntersect onEnter=(fn (mut this.isInViewport) true)}} @isLoading={{false}}>
      <:thead as |options|>
        <options.tr>
          <options.th>{{t "observability.documents.index.headers.filename"}}</options.th>
          <options.th>{{t "observability.documents.index.headers.provider"}}</options.th>
          <options.th>{{t "observability.documents.index.headers.status"}}</options.th>
          <options.th>{{t "observability.documents.index.headers.submitted"}}</options.th>
        </options.tr>
      </:thead>
      <:tbody as |options|>
        {{#if this.isInViewport}}
          {{#each this.documents as |document|}}
            <options.tr>
              <options.td>
                <LinkTo
                  @route="authenticated.observability.documents.show"
                  @model={{document}}
                  class="tio-anchor"
                >
                  {{document.observableAsset.filename}}
                </LinkTo>
              </options.td>
              <options.td>
                {{t "observable_document.provider" provider=(getProvider document)}}
              </options.td>
              <options.td>
                <Badge @intent={{getStatusIntent (getStatus document)}}>
                  {{t "observable_document.status" state=(getStatus document)}}
                </Badge>
              </options.td>
              <options.td>
                <div class="flex flex-row flex-nowrap justify-left items-center">
                  {{#let (getSubmittedIcon document.via) as |SubmittedIcon|}}
                    <SubmittedIcon class="w-6 mr-2" />
                  {{/let}}
                  {{getCreatedAt document}}
                </div>
              </options.td>
            </options.tr>
          {{/each}}
        {{/if}}
      </:tbody>
      <:tfoot as |options|>
        <options.tr>
          <options.td colspan="4">
            <Paginator @totalItems={{this.documents.meta.page.total}} @pageSize={{PAGE_SIZE}} />
          </options.td>
        </options.tr>
      </:tfoot>
    </Table>
    <TioPdfModal @show={{this.showPreview}} @onClose={{this.closePreviewModal}}>
      {{#if this.pdfSrc}}
        <div {{embedPdf this.pdfSrc}}></div>
      {{/if}}
    </TioPdfModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
