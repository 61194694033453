import { template as template_7810f52dee7c440499c90e96be016fa5 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsConsolidationRefinanceComponent: TOC = template_7810f52dee7c440499c90e96be016fa5(`
  <LinkTo @route="authenticated.repaying-student-debt.repayment-options.index">
    <Tile @headerText={{t "dashboard_tiles.consolidation_and_refinancing"}}>
      <:description>
        {{t "dashboard_tiles.learn_about_repayment"}}
      </:description>
      <:image>
        {{svgJar "questions" width="90%" height="100%" role="img" desc=(t "svg.questions")}}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsConsolidationRefinanceComponent;
