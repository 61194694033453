import { template as template_59ecb0f6818d44cc9613ff49502d8bc2 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { eq } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from '../../accounts/linked-loans-summary';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import SvgJar from 'ember-svg-jar/helpers/svg-jar';
import type { IntlService } from 'ember-intl';
import type { LoanSummary, SummaryByLoanType } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type RouterService from 'tio-employee/services/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
export interface WhatHappensNextSignature {
    Args: {
        accounts: AccountModel[];
        person: PersonModel;
        loanSummary: LoanSummary;
        programType: string;
    };
    Element: HTMLElement;
}
class RecResultsWhatHappensNextComponent extends Component<WhatHappensNextSignature> {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @tracked
    showMissingInfoDialog = false;
    @tracked
    showEstimatorBlockDialog = false;
    @tracked
    showGraduatedPlanBanner = false;
    @action
    checkLoansAndGoToEstimator() {
        const summary = this.args.loanSummary;
        const loanErrors = summary.errors || [];
        const noLoanErrors = loanErrors.length === 0;
        const hasSummaryByLoanType = summary.summaryByLoanType.length > 0;
        const hasGraduatedPlan = summary.summaryByLoanType.some((typeSummary: SummaryByLoanType)=>typeSummary.repaymentPlan === 'graduated');
        if (this.args.programType === 'sf' && hasGraduatedPlan) {
            this.showGraduatedPlanBanner = true;
            return;
        }
        if (!hasSummaryByLoanType) {
            this.showEstimatorBlockDialog = true;
            return;
        }
        if (noLoanErrors) {
            return this.args.programType === 'sf' ? this.router.transitionTo('authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire') : this.router.transitionTo('authenticated.idr.dashboard.forgiveness-estimator');
        } else {
            this.showMissingInfoDialog = true;
        }
    }
    static{
        template_59ecb0f6818d44cc9613ff49502d8bc2(`
    <Section>
      <:header>{{t "idr.results.what_happens_next"}}</:header>
      <:body>
        <VStack class="flex flex-col justify-center md:justify-start">
          <div class="max-w-24">
            {{SvgJar
              "crystal-ball"
              role="img"
              width="100%"
              height="100%"
              desc=(t "svg.crystal_ball")
            }}
          </div>
          <div class="flex">
            <MaterialIcon @icon="check" class="text-emerald-500 mx-1" aria-hidden="true" />
            <p class="mr-4">{{t "idr.results.recertify"}}</p>
          </div>
          <div class="flex justify-center md:justify-start">
            <MaterialIcon @icon="check" class="text-emerald-500 mx-1" aria-hidden="true" />
            <p class="mr-2">{{t "idr.results.financial_circumstances"}}</p>
          </div>
          <Button class="mr-auto" @intent="primary" {{on "click" this.checkLoansAndGoToEstimator}}>
            {{#if (eq @programType "sf")}}
              {{t "common.strategy_finder.default"}}
            {{else}}
              {{t "idr.results.payment_button"}}
            {{/if}}
          </Button>
        </VStack>
      </:body>
    </Section>
    {{! TODO: Could be one modal who's text is deisplayed based on state}}
    <Modal
      @isOpen={{this.showEstimatorBlockDialog}}
      @onClose={{fn (mut this.showEstimatorBlockDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Body>
        {{#if (eq @programType "sf")}}
          <p class="text-xl text-center text-gray-600 font-semibold mb-4">
            {{t "strategy_finder.before_proceeding"}}
          </p>
          <p>{{t "strategy_finder.we_will_not_be_able"}}</p>
        {{else}}
          <p class="text-xl text-center text-gray-600 font-semibold mb-4">
            {{t "pslf_dashboard.actions.dialog.before_proceeding"}}
          </p>
          <p>{{t "pslf_dashboard.actions.dialog.we_will_not_be_able"}}</p>
        {{/if}}
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{fn (mut this.showEstimatorBlockDialog) false}}
          @submitText={{t "ok"}}
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.showGraduatedPlanBanner}}
      @onClose={{fn (mut this.showGraduatedPlanBanner) false}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Body>
        <p class="text-xl text-center text-gray-600 font-semibold mb-4">
          {{t "pslf_dashboard.actions.dialog.graduated_plan"}}
        </p>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{fn (mut this.showEstimatorBlockDialog) false}}
          @submitText={{t "ok"}}
        />
      </m.Footer>
    </Modal>
    <Modal
      @isOpen={{this.showMissingInfoDialog}}
      @onClose={{fn (mut this.showMissingInfoDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        {{@person.firstName}},
        {{t "pslf_dashboard.actions.dialog.we_need_your_help"}}
      </m.Header>
      <m.Body>
        <p class="text-tio-gray-400 mb-5">
          {{t "pslf_dashboard.actions.dialog.please_provide_info"}}
        </p>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.checkLoansAndGoToEstimator}}
          @submitText={{t "common.continue"}}
          @onCancel={{fn (mut this.showMissingInfoDialog) false}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RecResultsWhatHappensNextComponent;
