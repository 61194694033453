import { template as template_2fbb4c8c01204f5d92fd232bce10d365 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
export default RouteTemplate(template_2fbb4c8c01204f5d92fd232bce10d365(`
    {{pageTitle (t "tuition_assistance.default")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
