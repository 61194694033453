import { template as template_9fdf410aa9b542a9b818d80cb4758a99 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
interface SlrRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SlrRouteSignature>(template_9fdf410aa9b542a9b818d80cb4758a99(`
    <VStack>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
