import { template as template_5922d0b5bf304772a55cf25eb538e257 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface RecommendationsArticleCardActionsSig {
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const RecommendationsArticleCardActions: TOC<RecommendationsArticleCardActionsSig> = template_5922d0b5bf304772a55cf25eb538e257(`
  <div class="flex flex-row flex-wrap justify-center mt-6 mb-2" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RecommendationsArticleCardActions;
