import { template as template_7e053cb7c6fa4a98a52552fdadcaa739 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { trackedFunction } from 'reactiveweb/function';
import Component from '@glimmer/component';
import matchMedia from 'tio-common/helpers/match-media';
import type CompanyModel from 'tio-common/models/company';
import type IntlService from 'ember-intl/services/intl';
import type PreRegisterService from 'tio-employee/services/pre-register';
import type Store from '@ember-data/store';
const defaultName = 'Tuition.io';
export interface AppHeaderBannerSignature {
    Args: {
        company?: CompanyModel;
    };
    Element: HTMLDivElement;
}
export default class AppHeaderBannerComponent extends Component<AppHeaderBannerSignature> {
    @service
    store: typeof Store;
    @service
    intl: IntlService;
    @service
    preRegister: PreRegisterService;
    get company() {
        return this.companyState?.value;
    }
    get companyName() {
        return this.company?.name || this.preRegister.companyName || defaultName;
    }
    get programTitle() {
        return (this.company?.programTitle || this.preRegister.programTitle || this.intl.t('header.banner.default_program_name'));
    }
    get logoUrl() {
        return this.preRegister.logo;
    }
    companyState = trackedFunction<Promise<CompanyModel | null>>(this, async ()=>{
        const companyId = this.args.company?.get('id');
        // `companyId` will only exist if the session is authenticated,
        //  otherwise we return `null` and fallback on the `preRegister` service
        //  for company banner values.
        return companyId ? await this.store.peekRecord('company', companyId, {
            include: 'logo'
        }) : null;
    });
    static{
        template_7e053cb7c6fa4a98a52552fdadcaa739(`
    <div
      id="header-title"
      class="flex flex-row items-center md:items-start justify-start md:flex-col grow"
      ...attributes
    >
      <img
        src={{this.logoUrl}}
        class="h-10 max-w-32 object-contain"
        alt={{this.companyName}}
        title={{this.companyName}}
      />
      {{#if (matchMedia "(min-width: 400px)")}}
        <div class="text-2xs text-gray-600 pt-1">
          {{t
            "header.banner.tagline"
            programTitle=this.programTitle
            brandClass="text-purple-900 font-semibold"
            htmlSafe=true
          }}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
