import { template as template_7f617c1bd15442619eb3bf311227346d } from "@ember/template-compiler";
import { VStack } from 'tio-ui/components/layout';
import NewTypeFourApp from 'tio-employee/components/tas/applications/new/type-four';
import RouteTemplate from 'ember-route-template';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
export interface S {
    Args: {
        model: {
            application: TasApplicationModel;
            programInstance: TASProgramInstanceModel;
        };
    };
}
export default RouteTemplate<S>(template_7f617c1bd15442619eb3bf311227346d(`
    <VStack>
      <NewTypeFourApp @model={{@model}} />
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
