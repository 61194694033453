import { template as template_0cef1f8ca5494d49b3663f968c3ea8c6 } from "@ember/template-compiler";
import { eq } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import Tippy from 'ember-tippy/components/tippy';
import type IntlService from 'ember-intl/services/intl';
interface StatusTextArgs {
    Args: {
        status: string;
        onShow?: () => void;
        onHide?: () => void;
        lastPaymentDate?: string;
    };
}
export default class StatusText extends Component<StatusTextArgs> {
    @service
    intl: IntlService;
    get status() {
        switch(this.args.status.toLowerCase()){
            case 'disbursed':
                return this.intl.t('slr.in_progress');
            case 'purgatory':
                return this.intl.t('slr.error.needs_attention');
            default:
                return this.args.status;
        }
    }
    get iconClass() {
        const iconColors: {
            [key: string]: string;
        } = {
            'Payment Sent': 'text-green-500',
            Pending: 'text-yellow-400',
            'In Progress': 'text-cyan-500',
            'Needs Attention': 'text-red-500'
        };
        return 'text-sm mr-1 ' + (iconColors[this.status] || 'text-yellow-400');
    }
    static{
        template_0cef1f8ca5494d49b3663f968c3ea8c6(`
    <div class="flex items-center">
      <MaterialIcon @icon="circle" class={{this.iconClass}} />
      <span class="underline capitalize">
        {{this.status}}
        <Tippy @placement="bottom" @onHidden={{@onHide}} @onShow={{@onShow}}>
          <div class="flex flex-col space-y-3">
            <h3>{{t "dashboard_tiles.slr_payment_tip_header"}}</h3>
            {{#if (eq @status "Payment Sent")}}
              {{#let
                (safeFormatDate @lastPaymentDate day="numeric" month="numeric" year="numeric")
                as |date|
              }}
                <p>{{t "dashboard_tiles.slr_payment_sent_tip_body" date=date}}</p>
              {{/let}}
            {{else if (eq @status "Pending")}}
              <p>{{t "dashboard_tiles.slr_payment_pending_tip_body"}}</p>
            {{else if (eq @status "In Progress")}}
              <p>{{t "dashboard_tiles.slr_in_progress_tip_body"}}</p>
            {{/if}}
          </div>
        </Tippy>
      </span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
