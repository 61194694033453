import { template as template_1079ad9c35c445518305037d26e3dca2 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import type TasApplicationModel from 'tio-common/models/tas-application';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard from 'tio-common/components/tuition-assistance/program-details/app-details/readonly-course-card';
import TuitionAssistanceProgramDetailsAppDetailsScholarshipCard from 'tio-common/components/tuition-assistance/program-details/app-details/scholarship-card';
import TuitionAssistanceApplicationDetailsCoursesStatusApproval from 'tio-common/components/tuition-assistance/application-details/courses-status-approval';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import type SessionContextService from 'tio-employee/services/session-context';
import { service } from '@ember/service';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
interface RouteSignature {
    Args: {
        model: TasApplicationModel;
    };
}
class TuitionAssistanceApplicationsShowReadonlyRouteComponent extends Component<RouteSignature> {
    @service
    sessionContext: SessionContextService;
    static{
        template_1079ad9c35c445518305037d26e3dca2(`
    <HStack>
      <VStack class="lg:!w-2/3 md:!w-1/2">
        <TuitionAssistanceApplicationDetailsEmployeeInformation
          @instance={{@model.tasProgramInstance}}
          @application={{@model}}
          @profileRoute="authenticated.admin.employees.show"
          @routeModel={{@model.tasProgramInstance.employee.id}}
        />
        <TuitionAssistanceProgramDetailsApplicationInformation
          @application={{@model}}
          @isAdminView={{true}}
          @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
          @isReadonlyView={{true}}
          @requestingApp="employee"
        />
        <TuitionAssistanceProgramDetailsProgramInfoCard
          @programInstance={{@model.tasProgramInstance}}
          @showAttachments={{true}}
        />
      </VStack>
      <Section class="w-full lg:!w-1/3 md:!w-1/2">
        <TuitionAssistanceApplicationDetailsApproverOverview
          @programTemplate={{@model.tasProgramInstance.tasProgramTemplate}}
        />
      </Section>
    </HStack>
    <Section>
      {{#each @model.activeCourses as |course|}}
        <div>
          <TuitionAssistanceProgramDetailsAppDetailsReadonlyCourseCard
            @course={{course}}
            @showCustomFields={{true}}
          />
        </div>
      {{/each}}
      <div>
        <TuitionAssistanceProgramDetailsAppDetailsScholarshipCard
          @application={{@model}}
          @isEditable={{false}}
        />
      </div>
    </Section>

    <Section>
      {{! TODO: see note on index version of this template.}}
      <TuitionAssistanceApplicationDetailsCoursesStatusApproval
        @application={{@model}}
        @programInstance={{@model.tasProgramInstance}}
        @routePrefix="authenticated"
        @isReadonlyView={{true}}
      />
    </Section>

    <TuitionAssistanceProgramDetailsAppDetailsAppHistory
      @application={{@model}}
      @viewType="admin"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TuitionAssistanceApplicationsShowReadonlyRouteComponent);
