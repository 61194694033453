import { template as template_4f916ef8c45d4daeacbef1bdb10626c5 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_4f916ef8c45d4daeacbef1bdb10626c5(`
    {{! template-lint-disable no-bare-strings }}
    <h1>Start page for testing</h1>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
