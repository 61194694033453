import { template as template_f5ed960d113d44758fdcc436062030b5 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import tippy from 'ember-tippy/modifiers/tippy';
import type AccountModel from 'tio-common/models/account';
import type LoanModel from 'tio-common/models/loan';
import Table from 'tio-common/components/table/index';
import { type TBodyOptions } from 'tio-common/components/table/index';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { get } from '@ember/object';
export interface AccountTableSignature {
    Args: {
        account: AccountModel;
    };
    Element: HTMLElement;
}
interface TableRowSignature {
    Args: {
        loan: LoanModel;
        paid?: boolean;
        options: typeof TBodyOptions;
    };
}
const TableRow: TOC<TableRowSignature> = template_f5ed960d113d44758fdcc436062030b5(`
  <@options.tr>
    <@options.td>{{@loan.name}}</@options.td>
    <@options.td>{{@loan.loanTypeDisplayName}}</@options.td>
    <@options.td>
      {{#if (get @loan.observation "repayment-plan")}}
        {{if @paid (t "accounts.paid_in_full") (get @loan.observation "repayment-plan")}}
      {{else}}
        {{t "common.unknown"}}
      {{/if}}
    </@options.td>
    <@options.td>{{if @paid (t "accounts.paid_in_full") @loan.status}}</@options.td>
    <@options.td>
      {{t
        "common.accounts.interest_due"
        interestPercentage=@loan.interestRate
        interestDue=(safeFormatNumber @loan.interestBalance style="currency" currency="USD")
      }}
    </@options.td>
    <@options.td>
      {{safeFormatNumber @loan.currentBalance style="currency" currency="USD"}}
    </@options.td>
    <@options.td>
      {{safeFormatNumber @loan.originalAmount style="currency" currency="USD"}}
      {{t "common.on"}}
      {{safeFormatDate @loan.originalDate month="short" day="2-digit" year="numeric"}}
    </@options.td>
  </@options.tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class AccountTable extends Component<AccountTableSignature> {
    get account() {
        return this.args.account;
    }
    get accountNumber() {
        return this.account.observation['payable-account-number'] || 'unknown';
    }
    get loans() {
        return this.account.activeLoans.flat();
    }
    get unpaidLoans() {
        return this.loans.filter((l)=>l.currentBalance);
    }
    get unpaidLoansTotalBalance() {
        return this.unpaidLoans.reduce((a, b)=>a + b.currentBalance, 0);
    }
    get paidLoans() {
        return this.loans.filter((loan)=>loan.status === 'PAID_IN_FULL');
    }
    get mailingAddress() {
        let readableAddress = this.account?.observation?.['payment-address'];
        if (!readableAddress) {
            return '';
        }
        // Insert a newline before c/o if it exists
        readableAddress = readableAddress.replace(/\sc\/o\s/gi, '\nc/o ');
        // Insert a newline before P.O. Box
        readableAddress = readableAddress.replace(/\sP\.O\.\sBox\s/gi, '\nP.O. Box ');
        // Insert newlines at commas
        readableAddress = readableAddress.replace(/,\s*/g, ',\n');
        // As a final fallback, you can also consider adding a newline before the state if it's the last part:
        // This is very format dependent. You might split on a state abbreviation and ZIP pattern:
        readableAddress = readableAddress.replace(/(\s[A-Z]{2}\s\d{5}(?:-\d{4})?)$/, '\n$1');
        return readableAddress.replace(/\n{2,}/g, '\n');
    }
    static{
        template_f5ed960d113d44758fdcc436062030b5(`
    <VStack @collapsed={{true}}>
      <Section>
        {{this.account.institutionName}}
        <div class="mb-2">
          <span class="block text-sm text-gray-700">{{t "accounts.date_as_of"}}
            {{safeFormatDate
              this.account.providerSuccessAt
              month="short"
              day="2-digit"
              year="numeric"
              timeZone="UTC"
            }}
          </span>
        </div>
        <HStack @collapsed={{true}}>
          <Section>
            <:header>{{t "accounts.payment_details"}}</:header>
            <:body>
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>{{t "accounts.account_number"}}</options.th>
                    <options.th>{{t "accounts.address"}}</options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  <options.tr class="align-top">
                    <options.td>{{this.accountNumber}}</options.td>
                    <options.td><pre>{{this.mailingAddress}}</pre></options.td>
                  </options.tr>
                </:tbody>
              </Table>
            </:body>
          </Section>
          <Section>
            <:header>{{t "accounts.account_details"}}</:header>
            <:body>
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>{{t "accounts.monthly_payment"}}</options.th>
                    <options.th>{{t "accounts.balance"}}</options.th>
                    <options.th>{{t "accounts.principal"}}</options.th>
                    <options.th>{{t "accounts.interest_total"}}</options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  <options.tr>
                    <options.td>
                      {{safeFormatNumber
                        this.account.providerRegularMonthlyPayment
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                    <options.td>
                      {{safeFormatNumber this.account.loanTotal style="currency" currency="USD"}}
                    </options.td>
                    <options.td>
                      {{safeFormatNumber
                        this.account.principalBalance
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                    <options.td>
                      {{safeFormatNumber
                        this.account.interestBalance
                        style="currency"
                        currency="USD"
                      }}
                    </options.td>
                  </options.tr>
                </:tbody>
              </Table>
            </:body>
          </Section>
        </HStack>
      </Section>
      <Section>
        {{! UNPAID LOANS - EXPANDABLE TABLE }}
        <Table @isLoading={{false}} ...attributes>
          <:thead as |options|>
            <options.tr>
              <options.th>{{t "common.name"}}</options.th>
              <options.th>
                {{t "common.type"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.type_info") placement="top"}}
                />
              </options.th>
              <options.th>
                {{t "accounts.repayment_plan"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.plan_info") placement="top"}}
                />
              </options.th>
              <options.th>
                {{t "status"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.status_info") placement="top"}}
                />
              </options.th>
              <options.th>
                {{t "pslf_dashboard.actions.dialog.interest_rate"}}
                <MaterialIcon
                  @icon="info"
                  class="text-lg ml-1 leading-none text-ocean-600"
                  {{tippy (t "accounts.rate_info") placement="top"}}
                />
              </options.th>
              <options.th>{{t "current_balance"}}</options.th>
              <options.th>{{t "accounts.date_borrowed"}}</options.th>
            </options.tr>
          </:thead>
          <:tbody as |options|>
            {{#each this.unpaidLoans as |loan|}}
              <TableRow @loan={{loan}} @options={{options}} />
            {{/each}}
            {{#each this.paidLoans as |loan|}}
              <TableRow @loan={{loan}} @paid={{true}} @options={{options}} />
            {{/each}}
          </:tbody>
          <:tfoot as |options|>
            <options.tr>
              <td class="border-t" colspan="7">
                <div class="text-right">
                  {{t "accounts.sub_total"}}
                  {{safeFormatNumber this.unpaidLoansTotalBalance style="currency" currency="USD"}}
                </div>
                <div class="text-right">
                  {{t "accounts.total_balance"}}
                  {{safeFormatNumber this.account.principalBalance style="currency" currency="USD"}}
                </div>
              </td>
            </options.tr>
          </:tfoot>
        </Table>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
